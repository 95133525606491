const en = {
    // pages helpers text
    BRANCH_LIST_PAGE_HELPER_TEXT: '',
    BANK_ACCOUNT_LIST_PAGE_HELPER_TEXT: '',
    BRANCH_CASHBOX_LIST_PAGE_HELPER_TEXT: '',
    CUSTOMER_LIST_PAGE_HELPER_TEXT: '',
    EXPENSE_LIST_PAGE_HELPER_TEXT: '',
    INVOICE_LIST_PAGE_HELPER_TEXT: '',
    PERMISSION_GROUP_LIST_PAGE_HELPER_TEXT: '',
    PRICING_GROUP_LIST_PAGE_HELPER_TEXT: '',
    RENTABLES_LIST_PAGE_HELPER_TEXT: '',
    RESERVATION_LIST_PAGE_HELPER_TEXT: '',
    SEASON_LIST_PAGE_HELPER_TEXT: '',
    AGENCY_LIST_PAGE_HELPER_TEXT: '',
    CLASS_LIST_PAGE_HELPER_TEXT: '',
    EXPENSE_CONFIG_LIST_PAGE_HELPER_TEXT: '',
    USER_LIST_PAGE_HELPER_TEXT: '',
    REJECTED_RESERVATIONS_LIST_PAGE_HELPER_TEXT: '',
    // configs categories
    GENERAL_SETTINGS: 'General Settings',
    RESERVATIONS: 'Reservations',
    FINANCIALS: 'Financials',
    RESERVATION: 'Reservation',
    FINANCIAL: 'Financial',
    OTHER: 'Other',
    // Configs fields
    VATID: 'VAT ID',
    TAXID: 'TAX ID',
    PHONENUMBER: 'Phone Number',
    INVOICENUMBER: 'Invoice Number',
    CONTRACTNUMBER: 'Contract Number',
    TRANSFERKMCOST: 'Transfer kilometric cost',
    ALLOWADDITIONALDRIVER: 'Allow additional driver',
    EXTRADRIVERFEE: 'Extra driver fee',
    FUELPOLICY: 'Fuel policy',
    ALLOWVAT: 'Allow VAT',
    VAT: 'VAT',
    REQUIRETRANSTERCOST: 'Required transfer cost',
    TRANSFERBASECOST: 'Transfer base cost',
    TRANSTERKMCOST: 'Transfer km cost',
    MAXDAILYKM: 'Maximum daily kilometers',
    EXCEEDEDMAXDAILYKMSURCHARGE: 'Exceeded max daily km surcharge',
    YOUNGrentablesURCHARGEPERCENTAGE: 'Young vehicle surcharge percentage',
    YOUNGrentablesURCHARGE: 'Young vehicle surcharge',
    YOUNGVEHICLEMONTHLIMIT: 'Young vehicle month limit',
    REQUIREDDEPOSIT: 'Required deposit',
    DEFERREDPAYMENTSURCHARGE: 'Deferred payment surcharge',
    NBRHOURSFORNEXTRESERVATION: 'Number of hours for the next reservation',
    REQUIRETRANSFERCOST: 'Required transfer cost',
    YOUNGVEHICLESURCHARGEPERCENTAGE: 'Young vehicle surcharge percentage',
    YOUNGVEHICLESURCHARGE: 'Young vehicle surcharge',
    DEPOSITAMOUNT: 'Deposit amount',
    DENYNOTPAIDRESERVATION: 'Deny not paid reservation',
    REQUIREDMANUALPRICE: 'Required manual price',
    MANUALPRICERESERVATIONPURPOSE: 'Manual Price Reservation Purpose',
    MANUAL_PRICE: 'Manual Price',
    UNPAID_PICKUP: 'Unpaid Pickup',
    CAR_DAMAGE_CONTINUE: 'Rentable Damage (Continue)',
    CAR_DAMAGE_CHANGE: 'Rentable Damage (Change)',
    CAR_DAMAGE_RETURN: 'Rentable Damage (Return)',
    CAR_DAMAGE_NON_FUNCTIONAL: 'Rentable Damage (Non-Functional)',
    CAR_DAMAGE: 'Rentable Damage',
    // helper field text
    'This is the main branch': 'This is the main branch',
    //
    'Default Bank Account': 'Default Bank Account',
    'You should be assigned to an agency to access this resource': 'You should be assigned to an agency to access this resource',
    'No expenses for today.': 'No expenses for today.',
    "Today's To-Do List": "Today's To-Do List",
    returns: 'returns',
    pickups: 'pickups',
    'New Agency': 'New Agency',
    'This will guide you through the process of creating a new agency for your account.': 'This will guide you through the process of creating a new agency for your account.',
    'To set up a new agency, kindly proceed by clicking on the following link': 'To set up a new agency, kindly proceed by clicking on the following link',
    'This will guide you through the process of creating a new agency for your account': 'This will guide you through the process of creating a new agency for your account',
    'Stamp must be a positive number': 'Stamp must be a positive number',
    'Current Contract Number must be a positive number': 'Current Contract Number must be a positive number',
    'Vat is required': 'Vat is required',
    'Driver Fee is required': 'Driver Fee is required',
    'Invoice Code': 'Invoice Code',
    'Expense Details': 'Expense Details',
    'Reservation already canceled, completed or not found !': 'Reservation already canceled, completed or not found !',
    'Rentable is not available for the selected dates!': 'Rentable is not available for the selected dates!',
    'Cannot change the type of branch cashbox with movements': 'Cannot change the type of branch cashbox with movements',
    'Some invoices were not found': 'Some invoices were not found',
    'New Branch Cashbox': 'New Branch Cashbox',
    'Create A New Branch Cashbox': 'Create A New Branch Cashbox',
    'Branch Cashboxes': 'Branch Cashboxes',
    Accounts: 'Accounts',
    'Iban must match letters and numbers only. Invalid code format. The code should be between 8 and 14 characters long and follow the pattern: AA-BB-CC-123 or aa-bb-cc-123.': 'Iban must match letters and numbers only. Invalid code format. The code should be between 8 and 14 characters long and follow the pattern: AA-BB-CC-123 or aa-bb-cc-123.',
    'Bank Account': 'Bank Account',
    'Branch Cashbox': 'Branch Cashbox',
    Movements: 'Movements',
    'Branch Cashbox Details': 'Branch Cashbox Details',
    'New Movement': 'New Movement',
    'Link Invoices': 'Link Invoices',
    In: 'In',
    Out: 'Out',
    IN: 'IN',
    OUT: 'OUT',
    'Movement is required': 'Movement is required',
    'Create new movement': 'Create new movement',
    'Description is required': 'Description is required',
    'Description must be at most 255 characters': 'Description must be at most 255 characters',
    Movement: 'Movement',
    'Invoices are required': 'Invoices are required',
    'No invoices!': 'No invoices!',
    'No expenses!': 'No expenses!',
    'CUSTOMER TRANSACTIONS': 'Customer Transaction',
    'CASH ON HAND': 'Cash On Hand',
    'Created By': 'Created By',
    demo: {
        title: `English`,
        introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    },
    'Amount is Required': 'Amount is Required',
    'Type is Required': 'Type is Required',
    'Amount must be a number': 'Amount must be a number',
    'Amount must be a positive number': 'Amount must be a positive number',
    'Rentable Availability Check': ' Rentable Availability Check',
    'Amount must be greater than 0': 'Amount must be greater than 0',
    Navigate: ' Navigate',
    'Apologies, but rental': 'Apologies, but rental',
    'is currently occupied by another reservation. Please choose an alternative rentable or use the button below to navigate to the other reservation and close it.': 'is currently occupied by another reservation. Please choose an alternative rentable or use the button below to navigate to the other reservation and close it.',
    'Expenses deleted successfully': 'Expenses deleted successfully',
    'Expense updated successfully': 'Expense updated successfully',
    'Branch cashboxes deleted successfully': 'Branch cashboxes deleted successfully',
    'day(s)': 'day(s)',
    Other: 'Other',
    'Upload Entries': 'Upload Entries',
    'bank accounts': 'bank accounts',
    'Search by Invoice...': 'Search by Invoice...',
    'Reservation is required': 'Reservation is required',
    'Due date is required': 'Due date is required',
    'Status is required': 'Status is required',
    'Customer is required': 'Customer is required',
    'Operation Date': 'Operation Date',
    UnPay: 'UnPay',
    Pay: 'Pay',
    'Value Date': 'Value Date',
    Credit: 'Credit',
    'Link Entry': 'Link Entry',
    'Entity Identifier': 'Entity Identifier',
    'Create & Link': 'Create & Link',
    'Download an example': 'Download an example',
    Upload: 'Upload',
    'Are you sure want to unlink': 'Are you sure you want to unlink',
    Unlink: 'Unlink',
    Link: 'Link',
    'Entity Identifier is required': 'Entity Identifier is required',
    'All invoices returned successfully': 'All invoices returned successfully',
    'Invoice created successfully': 'Invoice created successfully',
    'invoice found': 'invoice found',
    'invoices deleted successfully': 'invoices deleted successfully',
    'invoice deleted successfully': 'invoice deleted successfully',
    'invoice updated successfully': 'invoice updated successfully',
    'invoice status updated successfully': 'invoice status updated successfully',
    'invoice unlinked successfully': 'invoice unlinked successfully',
    'expense unlinked successfully': 'expense unlinked successfully',
    'Edit Branch Cashbox': 'Edit Branch Cashbox',
    'Some of the Branch cashbox were not found': 'Some of the Branch cashbox were not found',
    'Branch cashbox deleted successfully': 'Branch cashboxes deleted successfully',
    'Branch cashbox created successfully': 'Branch cashbox created successfully',
    'Create New Branch Cashbox': 'Create New Branch Cashbox',
    'An active CUSTOMER_TRANSACTIONS branch cashbox already exists': 'An active CUSTOMER_TRANSACTIONS branch cashbox already exists',
    'Branch cashbox updated successfully': 'Branch cashbox updated successfully',
    'Classes has been updated successfully !': 'Classes has been updated successfully !',
    'Classes created successfully !': 'Classes created successfully !',
    'invoice not found': 'invoice not found',
    'An active CASH_ON_HAND branch cashbox already exists': 'An active CASH_ON_HAND branch cashbox already exists',
    'Create A New Branch Cachbox': 'Create A New Branch Cachbox',
    'amount must be greater than or equal to 0': 'amount must be greater than or equal to 0',
    'invoice not found or already unlinked': 'invoice not found or already unlinked',
    'All bank entries returned successfully': 'All bank entries returned successfully',
    'bank entries created successfully': 'bank entries created successfully',
    'bank entries linked successfully': 'bank entries linked successfully',
    'bank Entry deleted successfully': 'bank Entry deleted successfully',
    'bank entries deleted successfully': 'bank entries deleted successfully',
    'bank account not found': 'bank account not found',
    'bank entry not found': 'bank entry not found',
    'bank entry not found or deleted': 'bank entry not found or deleted',
    Open: 'Open',
    Closed: 'Closed',
    Close: 'Close',
    Current: 'Current',
    Savings: 'Savings',
    Frozen: 'Frozen',
    'Cash On Hand': 'Cash On Hand',
    'Customer Transactions': 'Customer Transactions',
    SAVINGS: 'Savings',
    'Currency is required': 'Currency is required',
    Currencies: 'Currencies',
    'Account Owner Name': 'Account Owner Name',
    'Account Owner Name is Required': 'Account Owner Name is Required',
    'Bank Address is Required': 'Bank Address is Required',
    'Bank Name is Required': 'Bank Name is Required',
    Bank: 'Bank',
    'Account Type is Required': 'Account Type is Required',
    'Status is Required': 'Status is Required',
    'Currency is Required': 'Currency is Required',
    'Code is Required': 'Code is Required',
    Currency: 'Currency',
    'Edit Bank Account': 'Edit Bank Account',
    'Financial Source': 'Financial Source',
    'Account Owner Number': 'Account Owner Number',
    'Account Number': 'Account Number',
    'Initial Balance': 'Initial Credit Debit',
    'Iban Account Number': 'Iban Account Number',
    'Bank Address': 'Bank Address',
    'Bank Name': 'Bank Name',
    Balance: 'Credit Debit',
    'Bank Account Details': 'Bank Account Details',
    Label: 'Label',
    'Bank Entries': 'Bank Entries',
    'Contract number should be up to 8 digits long. Please enter a shorter number.': 'Contract number should be up to 8 digits long. Please enter a shorter number.',
    'The current contract number must not exceed 8 digits.': 'The current contract number must not exceed 8 digits.',
    'Occurrence Number must be a positive number greater than 0.': 'Occurrence Number must be a positive number greater than 0.',
    'The export process cannot proceed due to the absence of data.': 'The export process cannot proceed due to the absence of data.',
    Cash: 'Cash',
    cash: 'Cash',
    'Expense config deleted successfully': 'Expense categories deleted successfully',
    'Expense config updated successfully': 'Expense categories updated successfully',
    'Expense Occurrence': 'Expense Occurrence',
    'Expense Type': 'Expense Type',
    'Expense config created successfully': 'Expense category created successfully',
    'Create Expense Config': 'Create Expense category',
    'Expense config is required': 'Expense category is required',
    'User is required': 'User is required',
    'Start Date is required': 'Start Date is required',
    'Occurrence is required': 'Occurrence is required',
    'Occurrence Day is required': 'Occurrence Day is required',
    'End Date is required': 'End Date is required',
    'Occurrence Number is required': 'Occurrence Number is required',
    'Occurrence Number': 'Occurrence Number',
    'Rentable Extra is required': 'Rentable Extra is required',
    'Search By Expense Config Name': 'Search By Expense category Name',
    'New Expense Config': 'New Expense category',
    'Expense config': 'Expense category',
    'Expense Config': 'Expense category',
    'Edit Expense Config': 'Edit Expense category',
    'Edit config expense': 'Edit config expense',
    'Start Occurrence': 'Start Occurrence',
    'code must only contain hexadecimal characters': 'code must only contain hexadecimal characters',
    'user agency deleted successfully': 'user agency deleted successfully',
    'User already exists': 'User already exists',
    'A new confirmation link has been sent to your email': 'A new confirmation link has been sent to your email',
    'Your account has been activated': 'Your account has been activated',
    'Your account has been created successfully': 'Your account has been created successfully',
    '. However, there was an issue sending the reset password email. You can try to resend it from the forget password page, or contact support for assistance.': '. However, there was an issue sending the reset password email. You can try to resend it from the forget password page, or contact support for assistance.',
    'Password reset link has been sent to your email': 'Password reset link has been sent to your email',
    'User created successfully': 'User created successfully',
    'There was an issue sending the email. Please resend it from your account dashboard, or contact support for assistance.': 'There was an issue sending the email. Please resend it from your account dashboard, or contact support for assistance.',
    'Permissions assigned to group successfully': 'Permissions assigned to group successfully',
    'group deleted successfully': 'group deleted successfully',
    'Group created successfully': 'Group created successfully',
    'Group updated successfully': 'Group updated successfully',
    'Some expense configs not found': 'Some expense configs not found',
    'Expense Config not found': 'Expense category not found',
    'Some expenses are not found': 'Some expenses are not found',
    'Expense not found': 'Expense not found',
    'Group name already exists': 'Group name already exists',
    'Group not found': 'Group not found',
    Creator: 'Creator',
    'Expense Config Name': 'Expense category Name',
    'Expense Config Type': 'Expense category Type',
    'Type Recurring': 'Type Recurring',
    'One Time': 'One Time',
    quarterly: 'Quarterly',
    Recurring: 'Recurring',
    'Invalid or expired confirmation token': 'Invalid or expired confirmation token',
    'You have been successfully registered.': 'You have been successfully registered.',
    'Password must include at least one uppercase letter, one lowercase letter, one digit, and one of the special characters @, $, !, %, *, ?, or #': 'Password must include at least one uppercase letter, one lowercase letter, one digit, and one of the special characters @, $, !, %, *, ?, or #',
    Register: 'Register',
    'Current Contract Number': 'Current Contract Number',
    'Time Zone': 'Time Zone',
    'Create Your Agency': 'Create Your Agency',
    'group is required': 'group is required',
    'At least one item is required in the array': 'At least one item is required in the array',
    'Current Contract Number is required': 'Current Contract Number is required',
    'Time Zone is required': 'Time Zone is required',
    'Policy is required': 'Policy is required',
    'Hours For Next Reservation is required': 'Hours For Next Reservation is required',
    'Payment Surcharge is required': 'Payment Surcharge is required',
    'Code is required': 'Code is required',
    'Get started absolutely free.': 'Get started absolutely free.',
    'Already have an account?': 'Already have an account?',
    'You have to assign permissions before confirming': 'You have to assign permissions before confirming',
    docs: {
        hi: `Hi`,
        description: `Need help? \n Please check our docs.`,
        documentation: `documentation`,
    },
    Verify: 'Verify',
    'Invalid email format': 'Invalid email format',
    'Please check your email!': 'Please check your email!',
    'We have emailed a 8-digit confirmation code to ': 'We have emailed a 8-digit confirmation code to',
    'please enter the code in below box to verify your email.': 'please enter the code in below box to verify your email.',
    app: 'Application',
    user: 'User',
    list: 'List',
    edit: 'Edit',
    shop: 'Shop',
    blog: 'Blog',
    post: 'Post',
    seasons: 'Seasons',
    Seasons: 'Seasons',
    Season: 'Season',
    season: 'Season',
    PricingGroups: 'Pricing Groups',
    mail: 'Email',
    chat: 'Chat',
    branches: 'Branches',
    Branches: 'Branches',
    branch: 'Branch',
    Branch: 'Branch',
    cards: 'Cards',
    posts: 'Posts',
    create: 'Create',
    kanban: 'Kanban',
    general: 'General',
    banking: 'Banking',
    booking: 'Booking',
    profile: 'Profile',
    account: 'Account',
    product: 'Product',
    invoice: 'Invoice',
    details: 'Details',
    checkout: 'Checkout',
    calendar: 'Calendar',
    analytics: 'Analytics',
    ecommerce: 'E-commerce',
    management: 'Management',
    menu_level: 'Menu Level',
    menu_level_2a: 'Menu Level 2a',
    menu_level_2b: 'Menu Level 2b',
    menu_level_3a: 'Menu Level 3a',
    menu_level_3b: 'Menu Level 3b',
    menu_level_4a: 'Menu Level 4a',
    menu_level_4b: 'Menu Level 4b',
    'Search by Name...': 'Search by Name...',
    item_disabled: 'Disabled Item',
    item_label: 'Item Label',
    item_caption: 'Item Caption',
    item_external_link: 'Item External Link',
    description: 'Description',
    other_cases: 'Other Cases',
    item_by_roles: 'Item by Roles',
    only_admin_can_see_this_item: 'Only Admin Can See This Item',
    'rentable not available at the given date, please choose another date or explore other rentals!': 'rentable not available at the given date, please choose another date or explore other rentals!',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    'Total Rentables Count': 'Total Rentables Count',
    'Total Customer Count': 'Total Customer Count',
    'Total Branch Count': 'Total Branch Count',
    'Annual Revenue Overview': 'Annual Revenue Overview',
    'Annual Expenses Overview': 'Annual Expenses Overview',
    'Annual Net Income Overview': 'Annual Net Income Overview',
    'This Year': 'This Year',
    income: 'Income',
    outcome: 'Expenses',
    'Rentals Pick-up and Return Trends by Date': 'Rentals Pick-up and Return Trends by Date',
    'Class-based Financial Overview: Rentables Income vs. Expenses': 'Class-based Financial Overview: Rentables Income vs. Expenses',
    'Income and Expenses Trend Analysis by Date': 'Income and Expenses Trend Analysis by Date',
    'Reservation Distribution by Rentable Pricing Group': 'Reservation Distribution by Rentable Pricing Group',
    group: 'group',
    'No data available': 'No data available',
    class: 'Class',
    'No Data': 'No Data',
    'Rentables Pick-up and Return Trends by Date': 'Rentables Pick-up and Return Trends by Date',
    'Reservation Distribution by Class': 'Reservation Distribution by Class',
    'Pick up': 'Pick Up',
    'Pick Up': 'Pick Up',
    Pickup: 'Pickup',
    PickUp: 'Pickup',
    'pickup date is required': 'pickup date is required',
    Return: 'Return',
    'Daily Income Distribution by Branch': 'Daily Income Distribution by Branch',
    Today: 'Today',
    Yesterday: 'Yesterday',
    'This Week': 'This Week',
    'Last Week': 'Last Week',
    'This Month': 'This Month',
    'Last Month': 'Last Month',
    'days up to today': 'Days up to today',
    'days starting today': 'Days starting today',
    Confirm: 'Confirm',
    Clear: 'Clear',
    "Today's Daily Rentables Availability Report": "Today's Daily Rentables Availability Report",
    Logout: 'Logout',
    'Last 10 Reservation Records': 'Last 10 Reservation Records',
    'Pickup Address': 'Pickup Address',
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    Status: 'Status',
    'Identification Type Is Required': 'Identification Type Is Required',
    'Please provide id number': 'Please provide id number',
    'View All': 'View All',
    State: 'State',
    City: 'City',
    Address: 'Address',
    'Postal Code': 'Postal Code',
    'is Hq': 'Is HQ',
    'Is Hq': 'Is HQ',
    'Search by Name': 'Search by Name',
    'New Branch': 'New Branch',
    'New branch': 'New Branch',
    'Create A New Branch': 'Create A New Branch',
    'Create A New Class': 'Create A New Class',
    Settings: 'Settings',
    settings: 'Settings',
    ', and proceed with creating a new expense configuration.': ', and proceed with creating a new expense configuration.',
    'section, access': 'section, access',
    "You'll need to navigate to the": "You'll need to navigate to the",
    'How much surcharge the max daily km?': 'How much surcharge the max daily km?',
    'Require Transfer Cost': 'Require Transfer Cost',
    'Require transfer cost': 'Require transfer cost',
    'How much surcharge the deferred payment?': 'How much surcharge the deferred payment?',
    'Do you allow additional driver in your reservations?': 'Do you allow additional driver in your reservations?',
    'Deferred Payment Surcharge': 'Deferred Payment Surcharge',
    'Deferred payment surcharge': 'Deferred payment surcharge',
    'Transfer Base Cost': 'Transfer Base Cost',
    'Allow Additional Driver': 'Allow Additional Driver',
    'Allow additional driver': 'Allow additional driver',
    'Allow Vat Taxas': 'Allow Vat Taxas',
    'Allow vat taxas': 'Allow vat taxas',
    'Fuel policy': 'Fuel Policy',
    'Fuel policy ': 'Fuel Policy',
    'Fuel Policy': 'Fuel Policy',
    Full: 'Full',
    Partial: 'Partial',
    Empty: 'Empty',
    'Require Transfer Cost?': 'Require Transfer Cost?',
    'How much km per day?': 'How much km per day?',
    ExceededMaxDailyKmSurcharge: 'ExceededMaxDailyKmSurcharge',
    'Required Deposit': 'Required Deposit',
    Description: 'Description',
    'Drop files here or click': 'Drop files here or click',
    browse: 'Browse',
    'thorough your machine': 'through your machine',
    'Is Headquarters': 'Is Headquarters',
    'Create Branch': 'Create Branch',
    'Choose your Fuel Policy': 'Choose your Fuel Policy',
    'Nbr Hours For Next Reservation': 'Nbr Hours For Next Reservation',
    'Action Type': 'Action Type',
    'Rentable Type': 'Rentable Type',
    'Search by Registration Number...': 'Search by Registration Number...',
    'Registration Number': 'Registration Number',
    'R.N.': 'R.N.',
    'Payment Method': 'Payment Method',
    'Payment Methods': 'Payment Methods',
    'Edit an Expense': 'Edit an Expense',
    Amount: 'Amount',
    'Created At': 'Created At',
    'Create Expense': 'Create Expense',
    'Permission Management': 'Permission Management',
    Permissions: 'Permissions',
    Brand: 'Brand',
    'Branch Cashbox Movement': 'Branch Cashbox Movement',
    Dashboard: 'Dashboard',
    'FirstName must be at least 2 characters': 'FirstName must be at least 2 characters',
    'LastName must be at least 2 characters': 'LastName must be at least 2 characters',
    Rentables: 'Rentables',
    'New Rentable': 'New Rentable',
    'Create a new rentable': 'Create a new rentable',
    'Create Rentable': 'Create Rentable',
    'Max Daily Km': 'Max Daily Km',
    'Young Vehicle Surcharge Percentage': 'Young Vehicle Surcharge Percentage',
    'Young Vehicle Surcharge': 'Young Vehicle Surcharge',
    'Young Vehicle Month Limit': 'Young Vehicle Month Limit',
    'Create Class': 'Create Class',
    'Search by Class Name...': 'Search by Class Name...',
    'Default Daily Price': 'Default Daily Price',
    'Three Days Daily Price': 'Three Days Daily Price',
    'Week Daily Price': 'Week Daily Price',
    'Daily Price': 'Daily Price',
    'Month Daily Price': 'Month Daily Price',
    'Select Start Date ...': 'Select Start Date ...',
    'Select End Date ...': 'Select End Date ...',
    'Started On': 'Started On',
    'Finished On ': 'Finished On ',
    'Min Day': 'Min Day',
    'End Occurrence': 'End Occurrence',
    'Season Occurrence': 'Season Occurrence',
    'New Season': 'New Season',
    Monthly: 'Monthly',
    Annual: 'Annual',
    monthly: 'Monthly',
    annual: 'Annual',
    'Season Name': 'Season Name',
    'Is Periodic': 'Is Periodic',
    'Create Season': 'Create Season',
    'Default Three Days Daily Price': 'Default Three Days Daily Price',
    'Default Week Daily Price': 'Default Week Daily Price',
    'Default Month Daily Price': 'Default Month Daily Price',
    'Add Pricing Group Season': 'Add Pricing Group Season',
    'Pricing Group Season': 'Pricing Group Season',
    'Pricing Group Seasons': 'Pricing Group Seasons',
    'Add Rentables': 'Add Rentables',
    Edit: 'Edit',
    Tunisian: 'Tunisian',
    tunisian: 'Tunisian',
    View: 'View',
    Delete: 'Delete',
    Cars: 'Cars',
    Rentable: 'Rentable',
    Customer: 'Customer',
    'Return Date': 'Return Date',
    'Pick Up Date': 'Pick Up Date',
    'Total Amount': 'Total Amount',
    'Total Days': 'Total Days',
    "Today's Pick Up": "Today's Pick Up",
    "Tomorrow's Pick Up": "Tomorrow's Pick Up",
    "Today's Return": "Today's Return",
    "Tomorrow's Return": "Tomorrow's Return",
    Pending: 'Pending',
    pending: 'Pending',
    'In progress': 'In Progress',
    'In Progress': 'In Progress',
    inProgress: 'In Progress',
    Completed: 'Completed',
    completed: 'Completed',
    Canceled: 'Cancelled',
    canceled: 'Cancelled',
    "Customer's itinerary": "Customer's itinerary",
    'Select Rentable': 'Select Rentable',
    'Reserve the Rentable': 'Reserve the Rentable',
    'Pickup Location': 'Pickup Location',
    'Pickup location': 'Pickup Location',
    'Return at the same place': 'Return at the same place',
    'Back to reservations list': 'Back to reservations list',
    'Next Step': 'Next Step',
    Next: 'Next',
    'No options': 'No options',
    'Reservation Process': 'Reservation Process',
    Boats: 'Boats',
    Bicycles: 'Bicycles',
    Motorcycles: 'Motorcycles',
    Summary: 'Summary',
    From: 'From',
    To: 'To',
    'Total Price': 'Total Price',
    Total: 'Total',
    'Cost Transfer': 'Cost Transfer',
    costTransfer: 'Cost Transfer',
    Overload: 'Overload',
    'Discount Price': 'Discount Price',
    'Total Extra Price': 'Total Extra Price',
    Discount: 'Discount',
    'Manual Price': 'Manual Price',
    'TAX included if applicable': 'TAX included if applicable',
    Back: 'Back',
    'Select Customer': 'Select Customer',
    'First Conductor': 'First Conductor',
    'Select First Conductor': 'Select First Conductor',
    Add: 'Add',
    cancel: 'Cancel',
    'check out': 'Check Out',
    'Check Out': 'Check Out',
    Book: 'Book',
    'Return Location': 'Return Location',
    'Return location': 'Return Location',
    'Add Customer As FirstConductor': 'Add Customer As First Conductor',
    'Due Date': 'Due Date',
    'Equipment & Services': 'Equipment & Services',
    '+ Add Extras': '+ Add Extras',
    Extras: 'Extras',
    Drivers: 'Drivers',
    'No rentable is available for the dates provided!': 'No rentable is available for the dates provided!',
    'Consider selecting an alternative pricing group and exploring other options.': 'Consider selecting an alternative pricing group and exploring other options.',
    'Are you sure you want to pay?': 'Are you sure you want to pay?',
    'Select All': 'Select All',
    'Id Card Number': 'Id Card Number',
    Phone: 'Phone',
    'Payment type': 'Payment Type',
    Payment: 'Payment',
    Cancel: 'Cancel',
    Contract: 'Contract',
    Details: 'Details',
    Nationality: 'Nationality',
    Passport: 'Passport',
    'Pricing groups': 'Pricing Groups',
    'pricing groups': 'Pricing Groups',
    'Driving License': 'Driving License',
    'Residence Permit': 'Residence Permit',
    'Date Of Birth': 'Date Of Birth',
    'Ban Customer': 'Ban Customer',
    'Are you sure want to Ban?': 'Are you sure want to Ban?',
    Ban: 'Ban',
    'TIMELINE WEEK': 'TIMELINE WEEK',
    'TIMELINE MONTH': 'TIMELINE MONTH',
    'There is no data': 'There is no data',
    'agency customer not found or deleted': 'Agency customer not found or deleted',
    'User not registered': 'User not registered',
    'Credential not set': 'Credential not set',
    'Authentication failure': 'Authentication failure',
    'Invalid access token': 'Invalid access token',
    'Branch Location not found or deleted': 'Branch Location not found or deleted',
    'the name of the branch already exists': 'The name of the branch already exists',
    'Branch not found or deleted': 'Branch not found or deleted',
    'One or many rentables not found': 'One or many rentables not found',
    'Brand not found': 'Brand not found',
    'City not found': 'City not found',
    'Class not found or deleted': 'Class not found or deleted',
    'Config not found or deleted': 'Config not found or deleted',
    'The chosen config is not related to class': 'The chosen config is not related to class',
    'Rentable type not found or deleted': 'Rentable type not found or deleted',
    'Config value not found or deleted': 'Config value not found or deleted',
    'Config value scope is not valid': 'Config value scope is not valid',
    'Agency is not valid': 'Agency is not valid',
    'Config is not valid': 'Config is not valid',
    'class not found': 'Class not found',
    'Some classes not found or have been deleted': 'Some classes not found or have been deleted',
    'Config not found': 'Config not found',
    'value of given config not found': 'Value of given config not found',
    'ConfigValue not found': 'ConfigValue not found',
    'Customer already exists': 'Customer already exists',
    'customer not found or deleted': 'Customer not found or deleted',
    'Expense not found or deleted': 'Expense not found or deleted',
    'Location not found or deleted': 'Location not found or deleted',
    'Create A New Bank Account': 'Create A New Bank Account',
    'ModelImage not found': 'ModelImage not found',
    'Bank Accounts': 'Bank Accounts',
    'New Bank Account': 'New Bank Account',
    'Bank Entry': 'Bank Entry',
    'Config Value': 'Config Value',
    'Account Type': 'Account Type',
    'Model not found': 'Model not found',
    'season not found': 'Season not found',
    'you cannot create new pricing group season with used season': 'You cannot create new pricing group season with used season',
    'pricing group not found': 'Pricing group not found',
    'pricing group season not found or deleted': 'Pricing group season not found or deleted',
    'pricing group season not found': 'Pricing group season not found',
    'Some pricing groups not found or have been deleted': 'Some pricing groups not found or have been deleted',
    'pricing group not found or deleted': 'Pricing group not found or deleted',
    'pricingGroup not found': 'Pricing group not found',
    'there is no rentable available': 'There is no rentable available',
    'rentableAttribute not found': 'Rentable attribute not found',
    'rentableAttribute not found or deleted': 'Rentable attribute not found or deleted',
    'rentable attribute value not found': 'Rentable attribute value not found',
    'rentable attribute not found': 'Rentable attribute not found',
    'Rentables not found or deleted': 'Rentables not found or deleted',
    'rentable type is not extra': 'Rentable type is not extra',
    'Rentable not found or deleted': 'Rentable not found or deleted',
    'branch not found': 'Branch not found',
    'RentableExtraType not found': 'RentableExtraType not found',
    'Invalid rentable type': 'Invalid rentable type',
    'RentableType not found': 'RentableType not found',
    'Reservation Payment Method not found or deleted': 'Reservation Payment Method not found or deleted',
    'reservation item not found': 'Reservation item not found',
    'Permission denied': 'Permission denied',
    'Invalid Authorization': 'Invalid Authorization',
    'Invalid Access Token': 'Invalid Access Token',
    'Required configuration values for km exceed not found': 'Required configuration values for km exceed not found',
    'unable to calculate extra total price after pickup date change': 'Unable to calculate extra total price after pickup date change',
    "pickup date can't be after the return date": "Pickup date can't be after the return date",
    'unable to calculate rentable total price after pickup date change': 'Unable to calculate rentable total price after pickup date change',
    'Rentable group not found or deleted': 'Rentable group not found or deleted',
    'Pricing group not found or deleted': 'Pricing group not found or deleted',
    'unable to calculate rentable total price after return date change': 'Unable to calculate rentable total price after return date change',
    'You are not allowed to make reservations for this rentable': 'You are not allowed to make reservations for this rentable',
    'pickup location is required': 'Pickup location is required',
    'There is no available rentable for the selected dates': 'There is no available rentable for the selected dates',
    'No rentable available for the dates provided': 'No rentable available for the dates provided',
    'rentable type not found': 'Rentable type not found',
    'Invalid user': 'Invalid user',
    'user not found': 'User not found',
    'No permission': 'No permission',
    'Go to Home': 'Go to Home',
    "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.": "Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spelling.",
    "The page you're trying access has restricted access.": "The page you're trying access has restricted access.",
    'Please refer to your system administrator': 'Please refer to your system administrator',
    'payment Method is required': 'payment Method is required',
    'The user you are looking for could not be found or has been deleted!': 'The user you are looking for could not be found or has been deleted!',
    'The selected location is not available for pickup': 'The selected location is not available for pickup',
    'amount is required': 'amount is required',
    'The selected location is not available for return': 'The selected location is not available for return',
    'type is required': 'type is required',
    'Invalid email or password': 'Invalid email or password',
    'Upload Image': 'Upload Image',
    'Email already in use': 'Email already in use',
    'Password reset token invalid or expired': 'Password reset token invalid or expired',
    'Unable to send password reset email': 'Unable to send password reset email',
    'Reset password token not found or expired': 'Reset password token not found or expired',
    'Unable to reset password': 'Unable to reset password',
    'Cannot deactivate current user account': 'Cannot deactivate current user account',
    'Invalid verification token': 'Invalid verification token',
    'Unable to verify email': 'Unable to verify email',
    'User with this email not found': 'User with this email not found',
    'verification token not found or expired': 'Verification token not found or expired',
    'User already verified': 'User already verified',
    Unauthorized: 'Unauthorized',
    'Request failed with status code 404': 'Request failed with status code 404',
    'reservations without available cars': 'Reservations without available cars',
    'Please check and take necessary actions.': 'Please check and take necessary actions.',
    'Reservations without available cars:': 'Reservations without available cars:',
    'Total Reservations Count': 'Total Reservations Count',
    'See details': 'See details',
    Available: 'Available',
    'in circulation': 'In circulation',
    Prices: 'Prices',
    'Month Daily Price is required': 'Month Daily Price is required',
    'Week Daily Price is required': 'Week Daily Price is required',
    'Three Days Daily Price is required': 'Three Days Daily Price is required',
    'Daily Price is required': 'Daily Price is required',
    'Season is required': 'Season is required',
    'There are': 'There are',
    'reservations without available cars. Please check and take necessary actions': 'Reservations without available cars. Please check and take necessary actions',
    'Save Changes': 'Save Changes',
    'Last 7 Days': 'Last 7 Days',
    'Extra Driver Fee': 'Extra Driver Fee',
    'Extra driver fee': 'Extra driver fee',
    'Transfer Km Cost': 'Transfer Km Cost',
    'Transfer km cost': 'Transfer km cost',
    'Number Hours For Next ReserVation': 'Number Hours For Next ReserVation',
    'Number Hours For Next Reservation': 'Number Hours For Next Reservation',
    'number hours for next reservation': 'Number Hours For Next Reservation',
    'Rentable Type is required': 'Rentable Type is required',
    Name: 'Name',
    'Customer banned successfully': 'Customer banned successfully',
    'Customer(s) banned successfully': 'Customer(s) banned successfully',
    'customer banned': 'customer banned',
    banned: 'banned',
    Banned: 'Banned',
    'Customer Details': 'Customer Details',
    'Something went wrong': 'Something went wrong',
    'Start date must be after current date': 'Start date must be after current date',
    'You Cannot drag a reservation in Class Cell': 'You Cannot drag a reservation in Class Cell',
    'Only Pending Reservations are Draggable': 'Only Pending Reservations are Draggable',
    'Customer and First Conductor are required': 'Customer and First Conductor are required',
    'You have to choose a pricing group': 'You have to choose a pricing group',
    'SELECT DATE & TIME': 'SELECT DATE & TIME',
    'Return Location is required': 'Return Location is required',
    'Pickup Location is required': 'Pickup Location is required',
    'End date must be after start date': 'End date must be after start date',
    'Identity Card Information': 'Identity Card Information',
    'First Conductor Information': 'First Conductor Information',
    'Phone Number': 'Phone Number',
    'First Conductor License information': 'First Conductor License information',
    'Second Conductor': 'Second Conductor',
    'Select Second Conductor': 'Select Second Conductor',
    'second Conductor passport Number is required': 'Second Conductor passport Number is required',
    'second Conductor id Card Number is required': 'Second Conductor id Card Number is required',
    'second Conductor firstName is required': 'Second Conductor firstName is required',
    'second Conductor lastName is required': 'Second Conductor lastName is required',
    'second Conductor nationality is required': 'Second Conductor nationality is required',
    'second Conductor address is required': 'Second Conductor address is required',
    'second Conductor phone is required': 'Second Conductor phone is required',
    'Passport Number': 'Passport Number',
    AllowAdditionalDriver: 'Allow Additional Driver',
    ExtraDriverFee: 'Extra Driver Fee',
    RequireTransferCost: 'Require Transfer Cost',
    TransferBaseCost: 'Transfer Base Cost',
    TransferKmCost: 'Transfer Km Cost',
    MaxDailyKm: 'Max Daily Km',
    Class: 'Class',
    CreatedAt: 'Created At',
    Login: 'Login',
    Modal: 'Modal',
    Print: 'Print',
    Expenses: 'Expenses',
    Expense: 'Expense',
    fleet: 'Fleet',
    pricing: 'Pricing',
    Pricing: 'Pricing',
    customers: 'Customers',
    Customers: 'Customers',
    customer: 'Customer',
    Download: 'Download',
    dashboard: 'Dashboard',
    'ID Card': 'ID Card',
    'Search by Name, ID Card, Phone, Driving License, or Residence Permit...': 'Search by Name, ID Card, Phone, Driving License, or Residence Permit...',
    'Are you sure want to delete': 'Are you sure want to delete',
    selected: 'selected',
    'Are you sure want to delete?': 'Are you sure want to delete?',
    'New Expenses': 'New Expenses',
    'Pricing Group Details': 'Pricing Group Details',
    'Reservations Agenda': 'Reservations Agenda',
    'No Rentable available!': 'No Rentable available!',
    'Sorry, page not found!': 'Sorry, page not found!',
    'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.': 'Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.',
    'Go To Home': 'Go To Home',
    Calendar: 'Calendar',
    'Need Help?': 'Need Help?',
    'New Expense': 'New Expense',
    Update: 'Update',
    Password: 'Password',
    Actions: 'Actions',
    'Email address': 'Email address',
    'Sign in': 'Sign in',
    'Last 10 Return Records': 'Last 10 Return Records',
    'Last 10 Pickup Records': 'Last 10 Pickup Records',
    'Branch contain reservations, if you confirm you need to update them manually.': 'Branch contain reservations, if you confirm you need to update them manually.',
    items: 'items',
    'Add Extras': 'Add Extras',
    'Fuel Airconditional': 'Fuel Airconditional',
    'Transmission Drive': 'Transmission Drive',
    'Branch Details': 'Branch Details',
    'New Class': 'New Class',
    'Edit Expense': 'Edit Expense',
    'Edit season': 'Edit season',
    'Edit class': 'Edit class',
    'color Is Required': 'color Is Required',
    color: 'color',
    Category: 'Category',
    Classes: 'Classes',
    Color: 'Color',
    'Pricing Groups': 'Pricing Groups',
    'Pricing Group': 'Pricing Group',
    'New Group': 'New Group',
    'Edit Group': 'Edit Group',
    'Edit Pricing Group': 'Edit Pricing Group',
    'Create Group': 'Create Group',
    'New group': 'New Group',
    'New reservation': 'New reservation',
    'Edit rentable': 'Edit rentable',
    'Rentable Details': 'Rentable Details',
    'Edit Reservation': 'Edit Reservation',
    Submit: 'Submit',
    'Upload files': 'Upload files',
    'Remove all': 'Remove all',
    'Choose your car (or similar)': 'Choose your car (or similar)',
    True: 'True',
    False: 'False',
    filters: 'Filters',
    Filter: 'Filter',
    filter: 'Filter',
    Filters: 'Filters',
    AVAILABLE: 'AVAILABLE',
    Quantity: 'Quantity',
    Price: 'Price',
    'Reservation Details': 'Reservation Details',
    'select your car or similar': 'select your car or similar',
    'Class Details': 'Class Details',
    'Extra Type': 'Extra Type',
    'Branch Locations': 'Branch Locations',
    'Drop or Select file': 'Drop or Select file',
    'Edit branch': 'Edit branch',
    'Can Transfer': 'Can Transfer',
    Location: 'Locations',
    'Distance (Km)': 'Distance (Km)',
    Remove: 'Remove',
    'Add a branch location': 'Add a Branch Location',
    None: 'None',
    leasing: 'Leasing',
    washing: 'Washing',
    fuel: 'Fuel',
    maintains: 'Maintains',
    other: 'Other',
    case: 'Cash',
    transfer: 'Transfer',
    All: 'All',
    day: 'Day',
    'Expenses | Edit Expense': 'Expenses | Edit Expense',
    'Expenses | Create Expense': 'Expenses | Create Expense',
    'Number Hours For Next Reser Vation': 'Number of Hours for the Next Reservation',
    'How many hours do you need to prepare your rentable for the next reservation?': 'How many hours do you need to prepare your rental for the next reservation?',
    'If you allow extra driver, how much money do he need to pay?': 'If you allow an extra driver, how much money does he need to pay?',
    'Do you allow VAT tax?': 'Do you allow VAT tax?',
    'ACRISS Code': 'ACRISS Code',
    'Pricing group': 'Pricing Group',
    'Rental Type': 'Rental Type',
    'Finished On': 'Finished On',
    'Season occurrence': 'Season Occurrence',
    'There Is Reservation For This Rentable Are You Sure You Want To Delete It': 'There is a reservation for this rental. Are you sure you want to delete it?',
    'Pickup date': 'Pickup Date',
    'are reserved are you sure you want to delete them': 'are reserved are you sure you want to delete them',
    'Date of birth': 'Date of Birth',
    'There is': 'There is',
    'First name': 'First Name',
    'Last name': 'Last Name',
    'Driving license number': 'Driving License Number',
    'Driving license delivered date': 'Driving License Delivered Date',
    'Driving License Delivered Date': 'Driving License Delivered Date',
    'Id card number': 'ID Card Number',
    'Id card delivered date': 'ID Card Delivered Date',
    'Passport number': 'Passport Number',
    'Passport delivered date': 'Passport Delivered Date',
    // yup messages
    'Name is required': 'Name is required',
    'Name must be at least 2 characters': 'Name must be at least 2 characters',
    'Country is required': 'Country is required',
    'State is required': 'State is required',
    'City is required': 'City is required',
    'Address is required': 'Address is required',
    'Address must be at least 4 characters': 'Address must be at least 4 characters',
    'Postal Code is required': 'Postal Code is required',
    'Must be more than 4 digits and not null': 'Must be more than 4 digits and not null',
    'Location is required': 'Location is required',
    'Distance must be a positive number': 'Distance must be a positive number',
    'Distance is required': 'Distance is required',
    'Distance is required and must be a number': 'Distance is required and must be a number',
    'Extra Driver Fee is required': 'Extra Driver Fee is required',
    'Extra Driver Fee must be a positive number': 'Extra Driver Fee must be a positive number',
    'Transfer Base Cost is required': 'Transfer Base Cost is required',
    'Transfer Km Cost is required': 'Transfer Km Cost is required',
    'Required Deposit is required': 'Required Deposit is required',
    'Transfer Base Cost must be a positive number': 'Transfer Base Cost must be a positive number',
    'Transfer Km Cost must be a positive number': 'Transfer Km Cost must be a positive number',
    'Max Daily Km must be a positive number': 'Max Daily Km must be a positive number',
    'Exceeded Max Daily Km Surcharge must be a positive number': 'Exceeded Max Daily Km Surcharge must be a positive number',
    'Max Daily Km is required': 'Max Daily Km is required',
    'Exceeded Max Daily Km Surcharge is required': 'Exceeded Max Daily Km Surcharge is required',
    'Young Vehicle Month Limit is required': 'Young Vehicle Month Limit is required',
    'Young Vehicle Surcharge Percentage must be a positive number': 'Young Vehicle Surcharge Percentage must be a positive number',
    'Young Vehicle Surcharge must be a positive number': 'Young Vehicle Surcharge must be a positive number',
    'Young Vehicle Month Limit must be a positive number': 'Young Vehicle Month Limit must be a positive number',
    'Acriss Category is required': 'Acriss Category is required',
    'Acriss Fuel Airconditional is required': 'Acriss Fuel Airconditional is required',
    'Acriss Transmission Drive is required': 'Acriss Transmission Drive is required',
    'Acriss Type is required': 'Acriss Type is required',
    'Active Is Required': 'Active Is Required',
    'Rentable Modal Is Required': 'Rentable Modal Is Required',
    'Class Is Required': 'Class Is Required',
    'Brand Is Required': 'Brand Is Required',
    'Rentable Type Is Required': 'Rentable Type Is Required',
    'Branch Is Required': 'Branch Is Required',
    'Disabled From is Required': 'Disabled From is Required',
    'Registration number Is Required': 'Registration number Is Required',
    'Color Is Required': 'Color Is Required',
    'Registration number must be at least 3 characters': 'Registration number must be at least 3 characters',
    'Pricing Group Is Required': 'Pricing Group Is Required',
    'Daily Price must be a positive number': 'Daily Price must be a positive number',
    'Three DaysDailyPrice must be a positive number': 'Three Days Daily Price must be a positive number',
    'Week DailyPrice must be a positive number': 'Week Daily Price must be a positive number',
    'Month DailyPrice must be a positive number': 'Month Daily Price must be a positive number',
    'Min Day is required': 'Min Day is required',
    'Start date is required': 'Start date is required',
    'End date is required': 'End date is required',
    'Amount is required': 'Amount is required',
    'Amount must be positive number': 'Amount must be positive number',
    'Rentable is required': 'Rentable is required',
    'Type is required': 'Type is required',
    'Payment Method is required': 'Payment Method is required',
    'Date is required': 'Date is required',
    'Rental Type is required': 'Rental Type is required',
    'Rental Extra Type is required': 'Rental Extra Type is required',
    'Name must be at least 3 characters': 'Name must be at least 3 characters',
    'Price is required': 'Price is required',
    'Quantity is required': 'Quantity is required',
    'Price must be greater than or equal to 0': 'Price must be greater than or equal to 0',
    'Three Days Daily Price must be a positive number': 'Three Days Daily Price must be a positive number',
    'Week Daily Price must be a positive number': 'Week Daily Price must be a positive number',
    'Month Daily Price must be a positive number': 'Month Daily Price must be a positive number',
    'Delivered date must be less than or equal to the current day': 'Delivered date must be less than or equal to the current day',
    'Customer first name is required': 'Customer first name is required',
    'Customer first name must be at least 3 characters': 'Customer first name must be at least 3 characters',
    'Customer last name must be at least 3 characters': 'Customer last name must be at least 3 characters',
    'Customer must be at least 18 years old': 'Customer must be at least 18 years old',
    'Customer date of birth is required': 'Customer date of birth is required',
    'Customer nationality is required': 'Customer nationality is required',
    'Customer nationality must be at least 3 characters': 'Customer nationality must be at least 3 characters',
    'Customer address is required': 'Customer address is required',
    'Customer address must be at least 4 characters': 'Customer address must be at least 4 characters',
    'Customer phone is required': 'Customer phone is required',
    'Customer phone number must be at maximum 15 number': 'Customer phone number must be at maximum 15 numbers',
    'Customer phone number must be at least 8 number': 'Customer phone number must be at least 8 numbers',
    'Customer id card number must be at least 8 characters': 'Customer id card number must be at least 8 characters',
    'Customer id card number is required': 'Customer id card number is required',
    'Customer passport number is required': 'Customer passport number is required',
    'Customer passport number must be at least 8 characters': 'Customer passport number must be at least 8 characters',
    'First Conductor first name is required': 'First Conductor first name is required',
    'First Conductor first name must be at least 3 characters': 'First Conductor first name must be at least 3 characters',
    'First Conductor last name must be at least 3 characters': 'First Conductor last name must be at least 3 characters',
    'First Conductor must be at least 18 years old': 'First Conductor must be at least 18 years old',
    'First Conductor date of birth is required': 'First Conductor date of birth is required',
    'First Conductor nationality is required': 'First Conductor nationality is required',
    'First Conductor nationality must be at least 3 characters': 'First Conductor nationality must be at least 3 characters',
    'First Conductor address is required': 'First Conductor address is required',
    'First Conductor address must be at least 4 characters': 'First Conductor address must be at least 4 characters',
    'First Conductor phone is required': 'First Conductor phone is required',
    'First Conductor phone number must be at maximum 15 number': 'First Conductor phone number must be at maximum 15 numbers',
    'First Conductor phone number must be at least 8 number': 'First Conductor phone number must be at least 8 numbers',
    'First Conductor id card number must be at least 8 characters': 'First Conductor id card number must be at least 8 characters',
    'First Conductor id card number is required': 'First Conductor id card number is required',
    'First Conductor passport number is required': 'First Conductor passport number is required',
    'First Conductor passport number must be at least 8 characters': 'First Conductor passport number must be at least 8 characters',
    'Second Conductor first name is required': 'Second Conductor first name is required',
    'Second Conductor first name must be at least 3 characters': 'Second Conductor first name must be at least 3 characters',
    'Second Conductor last name must be at least 3 characters': 'Second Conductor last name must be at least 3 characters',
    'Second Conductor must be at least 18 years old': 'Second Conductor must be at least 18 years old',
    'Second Conductor date of birth is required': 'Second Conductor date of birth is required',
    'Second Conductor nationality is required': 'Second Conductor nationality is required',
    'Second Conductor nationality must be at least 3 characters': 'Second Conductor nationality must be at least 3 characters',
    'Second Conductor address is required': 'Second Conductor address is required',
    'Second Conductor address must be at least 4 characters': 'Second Conductor address must be at least 4 characters',
    'Second Conductor phone is required': 'Second Conductor phone is required',
    'Second Conductor phone number must be at maximum 15 number': 'Second Conductor phone number must be at maximum 15 numbers',
    'Second Conductor phone number must be at least 8 number': 'Second Conductor phone number must be at least 8 numbers',
    'Second Conductor id card number must be at least 8 characters': 'Second Conductor id card number must be at least 8 characters',
    'Second Conductor id card number is required': 'Second Conductor id card number is required',
    'Second Conductor passport number is required': 'Second Conductor passport number is required',
    'Second Conductor passport number must be at least 8 characters': 'Second Conductor passport number must be at least 8 characters',
    'Please provide a driving license number': 'Please provide a driving license number',
    'Add second conductor': 'Add Second Conductor',
    'First conductor': 'First Conductor',
    'Second conductor': 'Second Conductor',
    Birthday: 'Birthday',
    'Driver license information': 'Driver License Information',
    'Card delivered date': 'Card Delivered Date',
    'Identity card information': 'Identity Card Information',
    'Phone number': 'Phone Number',
    'Phone number *': 'Phone Number *',
    // Response messages
    'State not found': 'State not found',
    'the name of the season already exists': 'The name of the season already exists',
    'Season not found or deleted': 'Season not found or deleted',
    'rentable not found': 'Rentable not found',
    'Not authorized location!': 'Not authorized location!',
    'first conductor not found': 'First conductor not found',
    'An error occurred while updating the first conductor': 'An error occurred while updating the first conductor',
    'reservation not found or not pending': 'Reservation not found or not pending',
    'An error occurred while updating the reservation': 'An error occurred while updating the reservation',
    'payment method not found': 'Payment method not found',
    'No rentable available for the dates provided, consider choosing another pricing group and explore more options!': 'No rentable available for the dates provided, consider choosing another pricing group and explore more options!',
    'This rentable is currently not available for the dates provided, consider choosing another rentable and explore more options!': 'This rentable is currently not available for the dates provided, consider choosing another rentable and explore more options!',
    'Warning: additional driver is not allowed so it will be ignored in the reservation process': 'Warning: additional driver is not allowed so it will be ignored in the reservation process',
    'Extra not found or not available': 'Extra not found or not available',
    'return location is required': 'Return location is required',
    'The reservation you are looking for could not be found, or it is not currently in a pending or in-progress state!': 'The reservation you are looking for could not be found, or it is not currently in a pending or in-progress state!',
    'The reservation item you are looking for could not be found, or the reservation is with no rentable!': 'The reservation item you are looking for could not be found, or the reservation is with no rentable!',
    'The rentable in this reservation could not be found, please try to edit the reservation and select a new rentable!': 'The rentable in this reservation could not be found, please try to edit the reservation and select a new rentable!',
    'Given class is not compatible with rentable class': 'Given class is not compatible with rentable class',
    'Cannot resize this reservation because the rentable has no pricing group!': 'Cannot resize this reservation because the rentable has no pricing group!',
    'You cannot drag and drop a reservation to a past date!': 'You cannot drag and drop a reservation to a past date!',
    'You can only extend or change the rentable of pending reservations!': 'You can only extend or change the rentable of pending reservations!',
    'You cannot resize the reservation and change the rentable at the same time!': 'You cannot resize the reservation and change the rentable at the same time!',
    'The rentable you are looking to reserve could not be found!': 'The rentable you are looking to reserve could not be found!',
    'This rentable has no pricing group, consider exploring other rentals!': 'This rentable has no pricing group, consider exploring other rentals!',
    'No rentable for reservation, must select a pricing group!': 'No rentable for reservation, must select a pricing group!',
    'Location not found !': 'Location not found !',
    'Reservation not found': 'Reservation not found',
    'Reservation Payment not found': 'Reservation Payment not found',
    'there is no expenses': 'There is no expenses',
    'pricing group season created successfully': 'Pricing group season created successfully',
    'pricing group seasons deleted successfully': 'Pricing group seasons deleted successfully',
    'rentables added to pricing group successfully': 'Rentables added to pricing group successfully',
    'rentables removed from pricing group successfully': 'Rentables removed from pricing group successfully',
    'Unable to logout!': 'Unable to logout!',
    'Branch created successfully': 'Branch created successfully',
    'Branch updated successfully': 'Branch updated successfully',
    'branch deleted successfully': 'Branch deleted successfully',
    'Class created successfully': 'Class created successfully',
    'Class updated successfully': 'Class updated successfully',
    'classes has been deleted successfully !': 'Classes has been deleted successfully !',
    'rentable retrieved successfully': 'Rentable retrieved successfully',
    'Rentable updated successfully': 'Rentable updated successfully',
    'rentable deleted successfully': 'Rentable deleted successfully',
    'rentables removed from branch successfully': 'Rentables removed from branch successfully',
    'rentables added to branch successfully': 'Rentables added to branch successfully',
    'Extra retrieved successfully': 'Extra retrieved successfully',
    'Extra updated successfully': 'Extra updated successfully',
    'Extras deleted successfully': 'Extras deleted successfully',
    'pricing group season updated successfully': 'Pricing group season updated successfully',
    'Expense created successfully': 'Expense created successfully',
    'expense found': 'Expense found',
    'expenses deleted successfully': 'Expenses deleted successfully',
    'expense deleted successfully': 'Expense deleted successfully',
    'expense edited successfully': 'Expense edited successfully',
    'All customers returned successfully': 'All customers returned successfully',
    'customers banned successfully': 'Customers banned successfully',
    'customers deleted successfully': 'Customers deleted successfully',
    'Customer created successfully': 'Customer created successfully',
    'customer edited successfully': 'Customer edited successfully',
    'reservation and customer created successfully': 'Reservation and customer created successfully',
    'rentable attribute value updated successfully': 'Rentable attribute value updated successfully',
    'Second conductor deleted successfully': 'Second conductor deleted successfully',
    'Reservation canceled successfuly': 'Reservation cancelled successfuly',
    'reservation updated': 'Reservation updated',
    'reservation updated successfully': 'Reservation updated successfully',
    'cost transfer returned': 'Cost transfer returned',
    'seasons deleted successfully': 'Seasons deleted successfully',
    'Season created successfully': 'Season created successfully',
    'season deleted successfully': 'Season deleted successfully',
    'season edited successfully': 'Season edited successfully',
    'pricing groups deleted successfully': 'Pricing groups deleted successfully',
    'pricing group created successfully': 'Pricing group created successfully',
    'pricing group updated successfully': 'Pricing group updated successfully',
    'pricing group deleted successfully': 'Pricing group deleted successfully',
    'Extra created successfully': 'Extra created successfully',
    'rentable created successfully': 'Rentable created successfully',
    'Select dates': 'Select dates',
    'Rejected reservations': 'Rejected reservations',
    Reservation: 'Reservation',
    reservation: 'Reservation',
    Reservations: 'Reservations',
    reservations: 'Reservations',
    "Rejected reservations' List": "Rejected reservations' List",
    'Reservation Contract': 'Reservation Contract',
    Country: 'Country',
    Andorra: 'Andorre',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigéria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    'North Macedonia': 'Macédoine du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    'Palestine, State of': 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    Pitcairn: 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Réunion: 'Réunion',
    Romania: 'Roumanie',
    'Russian Federation': 'Russie',
    Rwanda: 'Rwanda',
    'New Caledonia': 'Nouvelle-Calédonie',
    'Saint Barthélemy': 'Saint-Barthélemy',
    Netherlands: 'Pays-Bas',
    Nauru: 'Nauru',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Île Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    'Micronesia, Federated States of': 'Micronésie',
    'Moldova, Republic of': 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar (Birmanie)',
    Namibia: 'Namibie',
    Nepal: 'Népal',
    'Saint Helena, Ascension and Tristan da Cunha': 'Sainte-Hélène',
    'Saint Kitts and Nevis': 'Saint-Christophe-et-Niévès',
    'Saint Lucia': 'Sainte-Lucie',
    'Timor-Leste': 'Timor oriental',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    'Turks and Caicos Islands': 'Îles Turks et Caïques',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Arab Emirates': 'Émirats arabes unis',
    'United Kingdom of Great Britain and Northern Ireland': 'Royaume-Uni',
    'United States of America': "États-Unis d'Amérique",
    'United States Minor Outlying Islands': 'Îles mineures éloignées des États-Unis',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Venezuela, Bolivarian Republic of': 'Venezuela',
    'Viet Nam': 'Vietnam',
    'Virgin Islands, British': 'Îles Vierges britanniques',
    'Virgin Islands, U.S.': 'Îles Vierges des États-Unis',
    'Wallis and Futuna': 'Wallis-et-Futuna',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Thailand: 'Thaïlande',
    'Tanzania, United Republic of': 'Tanzanie',
    Tajikistan: 'Tadjikistan',
    'Taiwan, Province of China': 'Taïwan',
    'Saint Martin (French part)': 'Saint-Martin (partie française)',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    'Saudi Arabia': 'Arabie saoudite',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten (Dutch part)': 'Saint-Martin (partie néerlandaise)',
    Liberia: 'Libéria',
    Slovakia: 'Slovaquie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Africa': 'Afrique du Sud',
    'South Georgia and the South Sandwich Islands': 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    Spain: 'Espagne',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    'Syrian Arab Republic': 'République arabe syrienne',
    Slovenia: 'Slovénie',
    Zimbabwe: 'Zimbabwe',
    Lesotho: 'Lesotho',
    Latvia: 'Lettonie',
    'Brunei Darussalam': 'Brunéi Darussalam',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cabo Verde': 'Cap-Vert',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    'Cayman Islands': 'Îles Caïmans',
    'Central African Republic': 'République centrafricaine',
    Chad: 'Tchad',
    Chile: 'Chili',
    China: 'Chine',
    'Christmas Island': 'Île Christmas',
    'Cocos (Keeling) Islands': 'Îles Cocos (Keeling)',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    Congo: 'Congo',
    'Congo, the Democratic Republic of the': 'République démocratique du Congo',
    'Cook Islands': 'Îles Cook',
    'Costa Rica': 'Costa Rica',
    "Côte d'Ivoire": "Côte d'Ivoire",
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Cyprus: 'Chypre',
    Czechia: 'Tchéquie',
    'British Indian Ocean Territory': "Territoire britannique de l'océan Indien",
    Denmark: 'Danemark',
    Brazil: 'Brésil',
    Afghanistan: 'Afghanistan',
    Albania: 'Albania',
    Algeria: 'Algeria',
    'American Samoa': 'American Samoa',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctica',
    'Antigua and Barbuda': 'Antigua and Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Aruba: 'Aruba',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bermuda: 'Bermuda',
    Bhutan: 'Bhutan',
    'Bolivia, Plurinational State of': 'Bolivia, Plurinational State of',
    'Bonaire, Sint Eustatius and Saba': 'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
    Botswana: 'Botswana',
    'Bouvet Island': 'Bouvet Island',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    'Dominican Republic': 'Dominican Republic',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    'El Salvador': 'El Salvador',
    'Equatorial Guinea': 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Eswatini: 'Eswatini',
    Ethiopia: 'Ethiopia',
    'Falkland Islands (Malvinas)': 'Falkland Islands (Malvinas)',
    'Faroe Islands': 'Faroe Islands',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    'French Guiana': 'French Guiana',
    'French Polynesia': 'French Polynesia',
    'French Southern Territories': 'French Southern Territories',
    Gabon: 'Gabon',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Greece',
    Greenland: 'Greenland',
    Grenada: 'Grenada',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernsey',
    Guinea: 'Guinea',
    'Guinea-Bissau': 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    'Heard Island and McDonald Islands': 'Heard Island and McDonald Islands',
    'Holy See': 'Holy See',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    'Iran, Islamic Republic of': 'Iran, Islamic Republic of',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    'Isle of Man': 'Isle of Man',
    Italy: 'Italy',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jersey: 'Jersey',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    "Korea, Democratic People's Republic of": "Korea, Democratic People's Republic of",
    'Korea, Republic of': 'Korea, Republic of',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    "Lao People's Democratic Republic": "Lao People's Democratic Republic",
    'Åland Islands': 'Åland Islands',
    'Cancel Type *': 'Cancel Type *',
    'Are you sure want to cancel the reservation?': 'Are you sure want to cancel the reservation?',
    Damaged: 'Damaged',
    Undamaged: 'Undamaged',
    'Create a new agency': 'Create a new agency',
    'Create a New user': 'Create a New user',
    'New user': 'New user',
    'New agency': 'New agency',
    'Edit agency': 'Edit Agency',
    'Edit user': 'Edit user',
    'Create agency': 'Create agency',
    'Create user': 'Create user',
    'Rentable created without pricing group': 'Rentable created without pricing group',
    all: 'all',
    deposit: 'deposit',
    on_account: 'on account',
    penalty: 'penalty',
    'Bank transfer': 'Bank transfer',
    'Credit card': 'Credit card',
    'already exist HQ branch': 'already exist HQ branch',
    'firstConductor.firstName is required': "first Conductor's firstName is required",
    'customer.firstName is required': "customer's firstName is required",
    Active: 'Active',
    active: 'Active',
    users: 'Users',
    'HQ Branch': 'HQ Branch',
    'There already exists a headquarters branch. Do you want to set this branch as the headquarters?': 'There already exists a headquarters branch. Do you want to set this branch as the headquarters?',
    Yes: 'Yes',
    No: 'No',
    'Last Name': 'Last Name',
    'First Name': 'First Name',
    Role: 'Role',
    'Start Working Date': 'Start Working Date',
    'Allowed *.jpeg, *.jpg, *.png, *.gif': 'Allowed *.jpeg, *.jpg, *.png, *.gif',
    ADMIN: 'ADMIN',
    OPERATOR: 'OPERATOR',
    Email: 'Email',
    Stamp: 'Stamp',
    Logo: 'Logo',
    'max size of': 'max size of',
    Agencies: 'Agencies',
    Agency: 'Agency',
    'Search by Agency Name': 'Search by Agency Name',
    'Search by Users Name , Email ,Phone number': 'Search by Users Name , Email ,Phone number',
    Users: 'Users',
    User: 'User',
    'Full Name': 'Full Name',
    'FirstName is required': 'FirstName is required',
    'LastName is required': 'LastName is required',
    'Email is required': 'Email is required',
    'Email address is required': 'Email address is required',
    'Phone number is required': 'Phone number is required',
    'Start Working Date is required': 'Start Working Date is required',
    'Notes is required': 'Notes is required',
    'Agency name is required': 'Agency name is required',
    'Stamp is required': 'Stamp is required',
    'Role is required': 'Role is required',
    'Damage Report': 'Damage Report',
    'Please indicate the rentable state, the decision in case the damage is nonfunctional, and the description if necessary.': 'Please indicate the rentable state, the decision in case the damage is nonfunctional, and the description if necessary.',
    'Rentable state *': 'Rentable state *',
    'Decision *': 'Decision *',
    Report: 'Report',
    Functional: 'Functional',
    NonFunctional: 'NonFunctional',
    'Continue with the current rentable': 'Continue with the current rentable',
    'Change rentable': 'Change rentable',
    'Return rentable': 'Return rentable',
    'Payment Check': 'Payment Check',
    'There is a difference of': 'There is a difference of',
    'Do you want to give back the amount or a portion?': 'Do you want to give back the amount or a portion?',
    'The difference is': 'The difference is',
    'customer needs to pay': 'Customer needs to pay',
    'Give back the amount or a portion *': 'Give back the amount or a portion *',
    'Amount *': 'Amount *',
    'agency created successfully': 'agency created successfully',
    'agency updated successfully': 'agency updated successfully',
    'user agency created successfully': 'user agency created successfully',
    'user agency updated successfully': 'user agency updated successfully',
    'agencies deleted successfully': 'agencies deleted successfully',
    'users of agencies deleted successfully': 'users of agencies deleted successfully',
    'Would you like to continue?': 'Would you like to continue?',
    'Update created Invoice.': 'Update created Invoice.',
    'Amount must be greater than or equal to the required amount': 'Amount must be greater than or equal to the required amount',
    "However, you can't refund it as the invoice is linked.": "However, you can't refund it as the invoice is linked.",
    'Linked invoice. Create a new one?': 'Linked invoice. Create a new one?',
    'Linked invoice. Want to create a new one with the excess over the needed amount?': 'Linked invoice. Want to create a new one with the excess over the needed amount?',
    'Create new Invoice.': 'Create new Invoice.',
    agencies: 'agencies',
    agency: 'agency',
    'Agency Name': 'Agency Name',
    'overrideHQ is not allowed': 'Override HQ is not allowed',
    'Identity Type': 'Identity Type',
    'Identity Number': 'Identity Number',
    'Search by Name, Nationality, or Phone...': 'Search by Name, Nationality, or Phone...',
    cin: 'Cin',
    driving_license: 'Driving License',
    passport: 'Passport',
    residence_permit: 'Residence Permit',
    Paid: 'Paid',
    Difference: 'Difference',
    'No rentable available for the dates provided, consider choosing another pricingGroup and explore more options!': 'No rentable available for the dates provided, consider choosing another pricingGroup and explore more options!',
    'The customer has driven more kilometers than allowed for the day, so he needs to pay a fine of': 'The customer has driven more kilometers than allowed for the day, so he needs to pay a fine of',
    'Penalty Check': 'Penalty Check',
    'Pay penalty *': 'Pay penalty *',
    'Classes created successfully': 'Class created succesfully',
    'penalty return checked successfuly': 'Penalty return checked successfully',
    'report payment checked successfuly': 'Report payment checked successfully',
    'User Details': 'User Details',
    operator: 'Operator',
    'Is Active': 'Is Active',
    "you can't delete agency has a least one rentable": "You can't delete agency has a least one rentable",
    'Send to email': 'Send to email',
    'Please enter the customer email address:': 'Please enter the customer email address:',
    'email sent': 'E-mail sent',
    'Error sending email': 'Error sending email',
    'user has no agency': 'User has no agency',
    'Description is a required field': 'Description is a required field',
    'agency user not found': 'agency user not found',
    "you can't use existing email or phone number": "you can't use existing email or phone number",
    'A driving_license identification is required for the driver': 'A driving_license identification is required for the driver',
    'The date must be greater than or equal to the current date.': 'The date must be greater than or equal to the current date.',
    'rentable not available at the given date, please choose another date or explore other other rentals!': 'rentable not available at the given date, please choose another date or explore other other rentals!',
    'No more expensive pricing group, consider choose manually': 'No more expensive pricing group, consider choose manually',
    'Agency Details': 'Agency Details',
    'Estimated Amount': 'Estimated Amount',
    'email already used': 'email already used',
    'Passwords must match': 'Passwords must match',
    'Password Confirm': 'Password Confirm',
    'Password Confirm is required': 'Password Confirm is required',
    'Confirm account': 'Confirm account',
    'Password has been reset': 'Password has been reset',
    'Send confirmation account mail': 'Send confirmation account mail',
    Send: 'Send',
    'Email must be a valid email': 'Email must be a valid email',
    'Password reset link has been sent to your email.': 'Password reset link has been sent to your email.',
    'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.': 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
    Rejected: 'Rejected',
    'No user found with this email': 'No user found with this email',
    'User does not have any associated agencies': 'User does not have any associated agencies',
    'Invalid or expired reset token': 'Invalid or expired reset token',
    'Not Found': 'Not Found',
    'Send Verification Link': 'Send Verification Link',
    "Enter your email address to reset your password or activate your account. We'll send you a verification link to your email.": "Enter your email address to reset your password or activate your account. We'll send you a verification link to your email.",
    'Email Verification': 'Email Verification',
    'Email Address Verification': 'Email Address Verification',
    'Password Reset': 'Password Reset',
    'Forgot password?': 'Forgot password?',
    'Return to sign in': 'Return to sign in',
    "can't delete superadmin": "can't delete superadmin",
    "Reservation not paid, can't process pickup!": "Reservation not paid, can't process pickup!",
    'Manual Price Description': 'Manual Price Description',
    'Manual Price Description is required': 'Manual Price Description is required',
    'manualPriceDescription length must be at least 3 characters long': 'Manual Price Description length must be at least 5 characters long',
    'Nationality is required': 'Nationality is required',
    'km is required': 'km is required',
    'fuel is required': 'fuel is required',
    'first Conductor last name is required': 'first Conductor last name is required',
    'first Conductor first name is required': 'first Conductor first name is required',
    'first Conductor date of birth is required': 'first Conductor date of birth is required',
    'first Conductor phone is required': 'first Conductor phone is required',
    'first Conductor address is required': 'first Conductor address is required',
    'Permission Denied': 'Permission Denied',
    'You do not have permission to access this page': 'You do not have permission to access this page',
    'logo is a required field': 'logo is a required field',
    'Transfer Base Cost?': 'Transfer Base Cost?',
    'Transfer Km Cost?': 'Transfer Km Cost?',
    'Deposit is required?': 'Deposit is required?',
    'Deny Not Paid Reservation': 'Deny Not Paid Reservation',
    'Required Manual Price': 'Required Manual Price',
    'Young vehicle month limit?': 'Young vehicle month limit?',
    'How much percentage surcharge the young vehicle payment?': 'How much percentage surcharge the young vehicle payment?',
    'How much surcharge the young vehicle payment?': 'How much surcharge the young vehicle payment?',
    "Reservation not paid, can't process pickup without providing the reason!": "Reservation not paid, can't process pickup without providing the reason!",
    // nationalities
    Afghan: 'Afghan',
    'Åland Island': 'Åland Island',
    Albanian: 'Albanian',
    Algerian: 'Algerian',
    'American Samoan': 'American Samoan',
    Andorran: 'Andorran',
    Angolan: 'Angolan',
    Anguillan: 'Anguillan',
    Antarctic: 'Antarctic',
    'Antiguan or Barbudan': 'Antiguan or Barbudan',
    Argentine: 'Argentine',
    Armenian: 'Armenian',
    Aruban: 'Aruban',
    Australian: 'Australian',
    Austrian: 'Austrian',
    'Azerbaijani, Azeri': 'Azerbaijani, Azeri',
    Bahamian: 'Bahamian',
    Bahraini: 'Bahraini',
    Bangladeshi: 'Bangladeshi',
    Barbadian: 'Barbadian',
    Belarusian: 'Belarusian',
    Belgian: 'Belgian',
    Belizean: 'Belizean',
    'Beninese, Beninois': 'Beninese, Beninois',
    'Bermudian, Bermudan': 'Bermudian, Bermudan',
    Bhutanese: 'Bhutanese',
    Bolivian: 'Bolivian',
    Bonaire: 'Bonaire',
    'Bosnian or Herzegovinian': 'Bosnian or Herzegovinian',
    'Motswana, Botswanan': 'Motswana, Botswanan',
    Brazilian: 'Brazilian',
    BIOT: 'BIOT',
    Bruneian: 'Bruneian',
    Bulgarian: 'Bulgarian',
    Burkinabé: 'Burkinabé',
    Burundian: 'Burundian',
    'Cabo Verdean': 'Cabo Verdean',
    Cambodian: 'Cambodian',
    Cameroonian: 'Cameroonian',
    Canadian: 'Canadian',
    Caymanian: 'Caymanian',
    'Central African': 'Central African',
    Chadian: 'Chadian',
    Chilean: 'Chilean',
    Chinese: 'Chinese',
    'Cocos Island': 'Cocos Island',
    Colombian: 'Colombian',
    'Comoran, Comorian': 'Comoran, Comorian',
    Congolese: 'Congolese',
    'Cook Island': 'Cook Island',
    'Costa Rican': 'Costa Rican',
    Ivorian: 'Ivorian',
    Croatian: 'Croatian',
    Cuban: 'Cuban',
    Curaçaoan: 'Curaçaoan',
    Cypriot: 'Cypriot',
    Czech: 'Czech',
    Danish: 'Danish',
    Djiboutian: 'Djiboutian',
    Dominican: 'Dominican',
    Ecuadorian: 'Ecuadorian',
    Egyptian: 'Egyptian',
    Salvadoran: 'Salvadoran',
    'Equatorial Guinean, Equatoguinean': 'Equatorial Guinean, Equatoguinean',
    Eritrean: 'Eritrean',
    Estonian: 'Estonian',
    Ethiopian: 'Ethiopian',
    'Falkland Island': 'Falkland Island',
    Faroese: 'Faroese',
    Fijian: 'Fijian',
    Finnish: 'Finnish',
    French: 'French',
    'French Guianese': 'French Guianese',
    'French Polynesian': 'French Polynesian',
    Gabonese: 'Gabonese',
    Gambian: 'Gambian',
    Georgian: 'Georgian',
    German: 'German',
    Ghanaian: 'Ghanaian',
    'Greek, Hellenic': 'Greek, Hellenic',
    Greenlandic: 'Greenlandic',
    Grenadian: 'Grenadian',
    'Guamanian, Guambat': 'Guamanian, Guambat',
    Guatemalan: 'Guatemalan',
    'Channel Island': 'Channel Island',
    Guinean: 'Guinean',
    'Bissau-Guinean': 'Bissau-Guinean',
    Guyanese: 'Guyanese',
    Haitian: 'Haitian',
    'Heard Island or McDonald Islands': 'Heard Island or McDonald Islands',
    Vatican: 'Vatican',
    Honduran: 'Honduran',
    'Hong Kong, Hong Kongese': 'Hong Kong, Hong Kongese',
    'Hungarian, Magyar': 'Hungarian, Magyar',
    Icelandic: 'Icelandic',
    Indian: 'Indian',
    Indonesian: 'Indonesian',
    'Iranian, Persian': 'Iranian, Persian',
    Iraqi: 'Iraqi',
    Irish: 'Irish',
    Manx: 'Manx',
    Israeli: 'Israeli',
    Italian: 'Italian',
    Jamaican: 'Jamaican',
    Japanese: 'Japanese',
    Jordanian: 'Jordanian',
    'Kazakhstani, Kazakh': 'Kazakhstani, Kazakh',
    Kenyan: 'Kenyan',
    'I-Kiribati': 'I-Kiribati',
    'North Korean': 'North Korean',
    'South Korean': 'South Korean',
    Kuwaiti: 'Kuwaiti',
    'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz': 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
    'Lao, Laotian': 'Lao, Laotian',
    Latvian: 'Latvian',
    Lebanese: 'Lebanese',
    Basotho: 'Basotho',
    Liberian: 'Liberian',
    Libyan: 'Libyan',
    Lithuanian: 'Lithuanian',
    'Luxembourg, Luxembourgish': 'Luxembourg, Luxembourgish',
    'Macanese, Chinese': 'Macanese, Chinese',
    Macedonian: 'Macedonian',
    Malagasy: 'Malagasy',
    Malawian: 'Malawian',
    Malaysian: 'Malaysian',
    Maldivian: 'Maldivian',
    'Malian, Malinese': 'Malian, Malinese',
    Maltese: 'Maltese',
    Marshallese: 'Marshallese',
    'Martiniquais, Martinican': 'Martiniquais, Martinican',
    Mauritanian: 'Mauritanian',
    Mauritian: 'Mauritian',
    Mahoran: 'Mahoran',
    Mexican: 'Mexican',
    Micronesian: 'Micronesian',
    Moldovan: 'Moldovan',
    'Mon\u00e9gasque, Monacan': 'Mon\u00e9gasque, Monacan',
    Mongolian: 'Mongolian',
    Montenegrin: 'Montenegrin',
    Montserratian: 'Montserratian',
    Moroccan: 'Moroccan',
    Mozambican: 'Mozambican',
    Burmese: 'Burmese',
    Namibian: 'Namibian',
    Nauruan: 'Nauruan',
    'Nepali, Nepalese': 'Nepali, Nepalese',
    'Dutch, Netherlandic': 'Dutch, Netherlandic',
    'New Caledonian': 'New Caledonian',
    'New Zealand, NZ': 'New Zealand, NZ',
    Nicaraguan: 'Nicaraguan',
    Nigerien: 'Nigerien',
    Nigerian: 'Nigerian',
    Niuean: 'Niuean',
    'Northern Marianan': 'Northern Marianan',
    Norwegian: 'Norwegian',
    Omani: 'Omani',
    Pakistani: 'Pakistani',
    Palauan: 'Palauan',
    Palestinian: 'Palestinian',
    Panamanian: 'Panamanian',
    'Papua New Guinean, Papuan': 'Papua New Guinean, Papuan',
    Paraguayan: 'Paraguayan',
    Peruvian: 'Peruvian',
    'Philippine, Filipino': 'Philippine, Filipino',
    'Pitcairn Island': 'Pitcairn Island',
    Polish: 'Polish',
    Portuguese: 'Portuguese',
    'Puerto Rican': 'Puerto Rican',
    Qatari: 'Qatari',
    'R\u00e9unionese, R\u00e9unionnais': 'R\u00e9unionese, R\u00e9unionnais',
    Romanian: 'Romanian',
    Russian: 'Russian',
    Rwandan: 'Rwandan',
    'Barth\u00e9lemois': 'Barth\u00e9lemois',
    'Saint Helenian': 'Saint Helenian',
    'Kittitian or Nevisian': 'Kittitian or Nevisian',
    'Saint Lucian': 'Saint Lucian',
    'Saint-Martinoise': 'Saint-Martinoise',
    'Saint-Pierrais or Miquelonnais': 'Saint-Pierrais or Miquelonnais',
    'Saint Vincentian, Vincentian': 'Saint Vincentian, Vincentian',
    Samoan: 'Samoan',
    Sammarinese: 'Sammarinese',
    'S\u00e3o Tom\u00e9an': 'S\u00e3o Tom\u00e9an',
    'Saudi, Saudi Arabian': 'Saudi, Saudi Arabian',
    Senegalese: 'Senegalese',
    Serbian: 'Serbian',
    Seychellois: 'Seychellois',
    'Sierra Leonean': 'Sierra Leonean',
    Singaporean: 'Singaporean',
    'Sint Maarten': 'Sint Maarten',
    Slovak: 'Slovak',
    'Slovenian, Slovene': 'Slovenian, Slovene',
    'Solomon Island': 'Solomon Island',
    'Somali, Somalian': 'Somali, Somalian',
    'South African': 'South African',
    'South Georgia or South Sandwich Islands': 'South Georgia or South Sandwich Islands',
    'South Sudanese': 'South Sudanese',
    Spanish: 'Spanish',
    'Sri Lankan': 'Sri Lankan',
    Sudanese: 'Sudanese',
    Surinamese: 'Surinamese',
    Svalbard: 'Svalbard',
    Swazi: 'Swazi',
    Swedish: 'Swedish',
    Swiss: 'Swiss',
    Syrian: 'Syrian',
    'Chinese, Taiwanese': 'Chinese, Taiwanese',
    Tajikistani: 'Tajikistani',
    Tanzanian: 'Tanzanian',
    Thai: 'Thai',
    Timorese: 'Timorese',
    Togolese: 'Togolese',
    Tokelauan: 'Tokelauan',
    Tongan: 'Tongan',
    'Trinidadian or Tobagonian': 'Trinidadian or Tobagonian',
    Turkish: 'Turkish',
    Turkmen: 'Turkmen',
    'Turks and Caicos Island': 'Turks and Caicos Island',
    Tuvaluan: 'Tuvaluan',
    Ugandan: 'Ugandan',
    Ukrainian: 'Ukrainian',
    'Emirati, Emirian, Emiri': 'Emirati, Emirian, Emiri',
    'British, UK': 'British, UK',
    American: 'American',
    Uruguayan: 'Uruguayan',
    'Uzbekistani, Uzbek': 'Uzbekistani, Uzbek',
    'Ni-Vanuatu, Vanuatuan': 'Ni-Vanuatu, Vanuatuan',
    Venezuelan: 'Venezuelan',
    Vietnamese: 'Vietnamese',
    'British Virgin Island': 'British Virgin Island',
    'U.S. Virgin Island': 'U.S. Virgin Island',
    'Wallis and Futuna, Wallisian or Futunan': 'Wallis and Futuna, Wallisian or Futunan',
    'Sahrawi, Sahrawian, Sahraouian': 'Sahrawi, Sahrawian, Sahraouian',
    Yemeni: 'Yemeni',
    Zambian: 'Zambian',
    Zimbabwean: 'Zimbabwean',
    view: 'View',
    delete: 'Delete',
    archive: 'Archive',
    unarchive: 'Unarchive',
    import: 'Import',
    export: 'Export',
    location: 'Location',
    pricingGroupSeason: 'Pricing Group Season',
    pricingGroup: 'Pricing Group',
    rentable: 'Rentable',
    reservationPayment: 'Reservation Payment',
    expenses: 'Expenses',
    payments: 'Payments',
    bank: 'Bank',
    'Financial source is Required': 'Financial source is Required',
    'Initial balance is Required': 'Initial Credit Debit is Required',
    rentableExtra: 'Rentable Extra',
    'Iban must match letters and numbers only.': 'Iban must match letters and numbers only.',
    'Rentable Extra': 'Rentable Extra',
    'Create a new season': 'Create a new season',
    'New season': 'New season',
    'Create a new Config Expense': 'Create a new Config Expense',
    'New config expense': 'New config expense',
    'iban must be at least 5 characters': 'iban must be at least 5 characters',
    'iban must be at most 34 characters': 'iban must be at most 34 characters',
    Models: 'Models',
    Model: 'Model',
    Groups: 'Groups',
    Group: 'Group',
    Admin: 'Admin',
    Operator: 'Operator',
    'Search...': 'Search...',
    role: 'Role',
    'An 8-digit confirmation code has been sent to your email. Please input the code to reset your password or activate your account. Set and confirm your new password, and you will be guided to the sign-in page.': 'An 8-digit confirmation code has been sent to your email. Please input the code to reset your password or activate your account. Set and confirm your new password, and you will be guided to the sign-in page.',
    Reset: 'Reset',
    'Resend code': 'Resend code',
    'Don’t have a code?': 'Don’t have a code?',
    'permission groups': 'permission groups',
    'Invalid file type': 'Invalid file type',
    Invoices: 'Invoices',
    invoices: 'invoices',
    Invoice: 'Invoice',
    'New invoice': 'New invoice',
    'Create A New Invoice': 'Create A New Invoice',
    Finance: 'Finance',
    'Invoice Items': 'Invoice Items',
    Change: 'Change',
    Select: 'Select',
    'TVA Percentage': 'TVA Percentage',
    TVA: 'TVA',
    'Add Invoice Item': 'Add Invoice Item',
    Qty: 'Qty',
    'Price / day': 'Price / day',
    'Invoice Number': 'Invoice Number',
    'Invoice Details': 'Invoice Details',
    PAID: 'Paid',
    UNPAID: 'UnPaid',
    UnPaid: 'UnPaid',
    Linked: 'Linked',
    UnLinked: 'UnLinked',
    'Invoice to': 'Invoice to',
    'Created Date': 'Created Date',
    'Tax included': 'Tax included',
    Subtotal: 'Subtotal',
    Create: 'Create',
    'excel file is required': 'excel file is required',
    'Rows per page': 'Rows per page',
    'create invoice': 'create invoice',
    'has an invoice': 'has an invoice',
};
export default en;
